<script>
export default {
  data() {
    return {
      paginator: { data: [] },
      options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },
      loaded: false,
      isLoading: false,
      callTimeout: null,
      error: null,
      overwriteData: true,
      tablePage: undefined,
    };
  },
  props: {
    params: {
      type: Object,
    },
  },
  watch: {
    search: {
      handler() {
        if (this.callTimeout !== null) clearTimeout(this.callTimeout);
        this.callTimeout = setTimeout(() => this.loadPaginator(), 300);
      },
    },
    params() {
      if (this.callTimeout !== null) clearTimeout(this.callTimeout);
      this.callTimeout = setTimeout(() => this.loadPaginator(), 100);
    },
    options: {
      deep: true,
      handler(oldValue, newValue) {
        const isEqual = require('lodash.isequal');
        if(isEqual(oldValue, newValue)) return;

        if (this.callTimeout !== null) clearTimeout(this.callTimeout);
        this.callTimeout = setTimeout(() => this.loadPaginator(), 100);
      },
    },
  },
  methods: {
    async loadPaginator() {
      try {
        this.isLoading = true;
        const requestParameters = [this.options.page, this.options.itemsPerPage, this.search, this.options.sortBy[0] || undefined, this.options.sortDesc[0] || undefined, this.params];
        const {
          data: {
            data,
            meta,
          },
        } = await this.indexRequest(...requestParameters);
        this.paginator.data = this.overwriteData ? data : [...this.paginator.data, ...data];
        this.paginator.meta = meta;

        this.loaded = true; // first time loaded
        this.isLoading = false; // loading changes
        this.pagination.page = this.paginator.meta.currentPage;
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>
