<template>
  <v-container fluid>
    <h1 class="text-h1 mb-3" v-if="loaded">{{ reportTitle }}</h1>
    <VSkeletonLoader v-else type="heading" class="mb-4"/>
    <v-col md="4" class="pa-0" v-if="canSearch">
      <VTextField solo v-model="search" :label="$t('actions.search')"/>
    </v-col>
    <v-card>
      <v-data-table
          v-if="loaded"
          :headers="datatableHeadersWithActionColumn"
          :items="paginator.data"
          :loading="isLoading"
          :page.sync="paginator.meta.currentPage"
          :server-items-length="paginator.meta.total"
          :footer-props="{itemsPerPageOptions: [10, 25, 100]}"
          :options.sync="options"
          @update:options="handleUpdatePagination">
        <template #body="props">
          <tbody>
            <tr v-for="(item, index) in props.items" :key="index">
              <td v-for="header in datatableHeaders"
                  :key="header.value"
                  class="text-start pa-0 clickable-column-wrapper">
                <v-chip v-if="header.chipCondition && item[convertSnakeToCamel(header.chipCondition)]" :color="header.chipState" class="ma-1"
                        :to="getNavigationToReport(header, item)">
                  {{ item[header.value] }}
                </v-chip>
                <router-link v-else-if="header.hasReport"
                             class="black--text text-decoration-none"
                             :to="getNavigationToReport(header, item)">
                  <div class="column">
                    {{ item[header.value] }}
                  </div>
                </router-link>
                <div class="column" v-else>
                  {{ item[header.value] }}
                </div>
              </td>
              <td>
                <template v-for="action in conditionBasedFilteredActions(item)">
                  <v-tooltip v-if="action.tooltip" left
                             :key="`${action.label}${item.orderNumber}`">
                    <template #activator="{on}">
                      <v-btn v-on="on"
                             small
                             color="primary"
                             text
                             @click="executeAction(action, item)">
                        {{ action.label }}
                      </v-btn>
                    </template>
                    {{ action.tooltip }}
                  </v-tooltip>
                  <v-btn
                      :key="`${action.label}${item.orderNumber}`"
                      v-else
                      small
                      text
                      color="primary"
                      @click="executeAction(action, item)">{{ action.label }}
                  </v-btn>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <VSkeletonLoader v-else type="table-thead, table-tbody, table-tfoot"/>
    </v-card>
  </v-container>
</template>

<script>
import { report } from '@/api/endpoints/report.js';
import Paginator from '@/application/mixins/Paginator.vue';
import { convertSnakeToCamel } from '@/api/util/keyConverter.js';
import { post } from '@/api/implementation/app';
import eventBus from '@/eventBus';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Report',
  mixins: [
    Paginator,
  ],
  data: () => ({
    datatableHeaders: [],
    reportTitle: '',
    actions: [],
    canSearch: true,
    search: '',
  }),
  watch: {
    '$route.params.reportType': {
      handler() {
        this.options.itemsPerPage = this.currentResourceParams?.itemsPerPage || 10
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("deviceSettings", ["getSelectedRouteResourceParams"]),
    currentResourceParams() {
      return this.getSelectedRouteResourceParams(this.currentRouteName);
    },
    currentRouteName() {
      return `${this.$route.name}.${this.$route.params.reportType}`;
    },
    datatableHeadersWithActionColumn() {
      if (this.actions.length === 0) {
        return this.datatableHeaders;
      }
      return [...this.datatableHeaders, { text: 'Acties' }];
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === from.name) {
      this.paginator.data = [];
      this.paginationIsCalled = false;
      this.loaded = false;
      this.loading = true;
      this.search = '';
      this.options.page = 1;
      this.options.itemsPerPage = 10;
      this.options.sortBy = [];
      this.options.sortDesc = [];
      if (this.callTimeout !== null) clearTimeout(this.callTimeout);
      this.callTimeout = setTimeout(() => this.loadPaginator(), 300);
    }
    next();
  },
  created() {
    this.loadPaginator();
  },
  methods: {
    convertSnakeToCamel,
    ...mapMutations("deviceSettings", ["setRouteResourceParams"]),
    async indexRequest(...args) {
      const query = Object.keys(this.$route?.query).reduce((query, snakeKey) => {
        return `${query}${query ? '&' : '?'}${snakeKey}=${this.$route?.query[snakeKey]}`;
      }, '');

      const response = await report(this.$route.params.reportType + query, ...args);
      this.reportTitle = response.data.title ?? this.$tc('report.title', 2);
      this.canSearch = response.data?.hasSearch ?? false;
      this.datatableHeaders = response.data.headers.map(header => {
        return {
          value: convertSnakeToCamel(header.key),
          text: header.label,
          ...header,
        };
      });
      if (response.data.actions) {
        this.actions = response.data.actions.map(action => {
          return {
            ...action,
            condition: action.condition ? convertSnakeToCamel(action.condition) : undefined,
            params: action.params.map(param => convertSnakeToCamel(param)),
          };
        });
      } else {
        this.actions = [];
      }

      return response;
    },
    handleUpdatePagination(pagination) {
      this.setRouteResourceParams({routeName: this.currentRouteName, params: {itemsPerPage: pagination.itemsPerPage}});
    },
    getNavigationToReport(header, item) {

      if (header.urlRoute) {

        let parsedRoute = this.parseUrlAndItemParams(header.urlRoute, header, item);

        return {
          path: "/" + parsedRoute.path,
          query: parsedRoute.query,
        };
      }

      let parsedRoute = this.parseUrlAndItemParams(header.reportSlug, header, item);

      return {
        name: 'report.show',
        params: {
          reportType: parsedRoute.path,
        },
        query: parsedRoute.query,
      };
    },
    parseUrlAndItemParams(url, header, item)
    {
      let splitUrl = url.split('?');

      // First place all query parameters that are located inside reportParams
      let query = header?.reportParams.reduce((query, snakeKey) => {
        query[snakeKey] = item[convertSnakeToCamel(snakeKey)];
        return query;
      }, {});

      // If there are more params within the reportSlug
      if (splitUrl[1]) {
        const parameters = splitUrl[1].split('&');

        parameters.forEach(parameter => {
          const result = parameter.split('=');
          query[result[0]] = result[1];
        });
      }

      return {
        path: splitUrl[0],
        query: query,
      };
    },
    /**
     * Check the condition of an action button against the item
     * the condition of an action is the string of a key of an item
     */
    conditionBasedFilteredActions(item) {
      return this.actions.filter(action => {
        if (!action.condition) {
          return true;
        }
        return !!item[action.condition];
      });
    },
    async executeAction(action, item) {
      const parameters = action.params.reduce((obj, parameter) => {
        return {
          ...obj,
          [parameter]: item[parameter],

        };
      }, {});
      const callable = async () => {

        try {
          await post(action.uri, parameters);

          await this.loadPaginator();
        } catch (e) {
          eventBus.$emit('snackbar', {
            color: 'error',
            text: this.$t('global.error'),
          });
        }
      };
      if (action.hasConfirm) {
        eventBus.$emit('confirm', {
          title: this.$t('global.confirm.title'),
          body: this.$t('global.confirm.body'),
          confirmCallback: callable,
        });
      } else {
        callable();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.clickable-column-wrapper {
  padding: 0;

  .column {
    padding: 8px 16px;
  }
}
</style>
